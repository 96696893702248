import React, { useState, useEffect, useContext } from 'react';
import PT from 'prop-types';
import { difference, isEmpty } from 'lodash';
import { formatAssetNavigationDataSetWiz } from 'utils';
import { useParams } from 'react-router-dom';
import { AssetContext, DataTemplatesContext } from '.';

export const DataSetsWizardContext = React.createContext();

export const DataSetsWizardProvider = ({ children }) => {
  const { allAssets, getAssets, setAllAssets } = useContext(AssetContext);
  const { templateAssets, assetTypesWithParentAndSubTypes } =
    useContext(DataTemplatesContext);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetNodes, setAssetNodes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [openDataSetWizard, setOpenDataSetWizard] = useState(false);
  const { dataTemplateId } = useParams();
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [topLevelAssetType, setTopLevelAssetType] = useState({
    type: '',
    available: false,
  });
  const handleOpenCloseWizard = () => {
    setOpenDataSetWizard(!openDataSetWizard);
  };
  const getAssetsForTemplate = async () => {
    const templates = [];
    const assetTypesForTemplate = templateAssets.reduce((acc, curr) => {
      if (curr.assetType) {
        templates.push(curr);
        acc.push(curr.assetType.id);
      }
      return acc;
    }, []);
    setAssetTypes([...templates]);
    await getAssets({
      asset_type_id: [...assetTypesForTemplate],
      show_deleted: false,
    });
  };

  const filterAssetsByType = (assets, ph) => {
    return assets.filter(
      asset =>
        ph.assetType &&
        asset.assetType?.id === ph.assetType?.id &&
        (!ph.assetSubType || asset.assetSubType?.id === ph.assetSubType?.id)
    );
  };

  const filterAssetsByParent = (phDict, filteredByTypeAssets, ph) => {
    const parentPh = phDict[ph.parentAssetPlaceholderId];
    if (!parentPh) {
      return filteredByTypeAssets;
    }
    const possibleParents = parentPh.possibleAssets || [];

    return filteredByTypeAssets.filter(asset => {
      if (asset.parent === null) {
        return asset;
      }

      return possibleParents.some(
        possibleParent => possibleParent.id === asset.parent
      );
    });
  };

  const processPlaceholders = (phDict, allAssetsArr, aphs, currentPh) => {
    const filteredByType = filterAssetsByType(allAssetsArr, currentPh);
    const filteredByParent = filterAssetsByParent(
      phDict,
      filteredByType,
      currentPh
    );
    // eslint-disable-next-line no-param-reassign
    phDict[currentPh.id].possibleAssets = filteredByParent;
    const aphChildren = aphs.filter(
      a => a.parentAssetPlaceholderId === currentPh.id
    );
    aphChildren.forEach(child =>
      processPlaceholders(phDict, allAssetsArr, aphs, child)
    );
  };

  useEffect(() => {
    if (templateAssets.length) {
      getAssetsForTemplate();
      setTopLevelAssetType('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateId, templateAssets]);

  useEffect(() => {
    if (templateAssets.length) {
      const topAssetType = templateAssets.find(
        placeholder => !placeholder.parentAssetPlaceholderId
      );
      if (topAssetType.assetType) {
        const availableAssets = allAssets.filter(
          asset => asset.assetType?.id === topAssetType.assetType.id
        );
        if (!availableAssets.length) {
          setTopLevelAssetType({
            type: topAssetType.assetType.name,
            available: false,
          });
        } else {
          setTopLevelAssetType({
            type: topAssetType.assetType.name,
            available: true,
          });
        }
      } else {
        setTopLevelAssetType({ type: '', available: true });
      }
    }
  }, [allAssets, templateAssets]);

  useEffect(() => {
    if (openDataSetWizard) {
      setAllAssets([]);
      getAssetsForTemplate();
      setSelectedAssets([]);
      setExpanded([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDataSetWizard, dataTemplateId]);

  useEffect(() => {
    if (openDataSetWizard && assetTypes.length) {
      let projectAssetsMatchingTemplateAssetTypeIds = [];

      const topAsset = templateAssets.find(
        asset => !asset.parentAssetPlaceholderId
      );

      if (!isEmpty(allAssets)) {
        const phDict = Object.fromEntries(
          templateAssets.map(a => [a.id, { possibleAssets: [] }])
        );
        const root = templateAssets.find(a => !a.parentAssetPlaceholderId);

        processPlaceholders(phDict, allAssets, templateAssets, root);

        const possibleAssetInstances = templateAssets.flatMap(
          a => phDict[a.id].possibleAssets
        );

        // check if all asset children are in the list of possible assets
        // if not set the children on the asset to an empty array
        const possibleAssetIds = possibleAssetInstances.map(a => a.id);
        const allAssetInstances = possibleAssetInstances.map(
          possibleInstance => {
            const hasAllChildren = possibleInstance.children.every(child =>
              possibleAssetIds.includes(child)
            );

            if (hasAllChildren) {
              return possibleInstance;
            }
            return {
              ...possibleInstance,
              children: [],
            };
          }
        );

        const addPhInfoToAssetInstances = (
          assetInstancesArr,
          templateAssetsArr,
          aph,
          parentDict
        ) => {
          const assetInstances = assetInstancesArr.filter(instance => {
            if (
              aph.parentAssetPlaceholderId === null &&
              aph.assetType === null
            ) {
              return false;
            }

            if (!parentDict[instance.parent]?.typeIndex) {
              return true;
            }

            return (
              instance.assetType?.id === aph.assetType?.id &&
              instance.assetSubType?.id === aph.assetSubType?.id &&
              parentDict[instance.parent].typeIndex.id ===
                aph.parentAssetPlaceholderId
            );
          });

          assetInstances.forEach(instance => {
            // eslint-disable-next-line no-param-reassign
            parentDict[instance.id] = {
              ...instance,
              typeIndex: aph,
            };
          });

          const reducedAssetInstances = difference(
            assetInstancesArr,
            assetInstances
          );

          const childrenPh = templateAssetsArr.filter(
            templateAsset => templateAsset.parentAssetPlaceholderId === aph.id
          );

          childrenPh.forEach(child => {
            addPhInfoToAssetInstances(
              reducedAssetInstances,
              templateAssetsArr,
              child,
              parentDict
            );
          });
        };

        const parDict = Object.fromEntries(
          allAssetInstances.map(a => [a.id, { ...a, typeIndex: {} }])
        );

        addPhInfoToAssetInstances(
          allAssetInstances,
          templateAssets,
          topAsset,
          parDict
        );

        projectAssetsMatchingTemplateAssetTypeIds = Object.values(parDict);
      }

      const assetTypesWithInfo = assetTypes.reduce((acc, curr) => {
        const parent = assetTypes.find(
          asset => asset.id === curr.parentAssetPlaceholderId
        );
        acc.push({
          ...curr,
          assetType: curr.assetType,
          assetSubType: curr.assetSubType,
          parent: parent?.id || null,
          isTypeOnly: true,
        });
        return acc;
      }, []);

      const nodes = formatAssetNavigationDataSetWiz(
        [...projectAssetsMatchingTemplateAssetTypeIds, ...assetTypesWithInfo],
        null,
        topAsset?.assetType?.id ? topAsset.assetType.id : null,
        !!topAsset?.assetType?.id,
        null,
        assetTypesWithParentAndSubTypes
      );
      setAssetNodes(nodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAssets]);

  useEffect(() => {
    const templateLevels = templateAssets.filter(
      templateAsset => !isEmpty(templateAsset.assetType)
    ).length;

    let depthCounter = 0;
    if (templateLevels >= 5) depthCounter = 3;
    else depthCounter = 2;

    const createInitialExpandedNodes = (nodes, counter = depthCounter) => {
      if (counter < 1) return [];
      const expandedNodes = [];
      expandedNodes.push(nodes?.id);
      if (!isEmpty(nodes?.children)) {
        nodes.children.forEach(child => {
          expandedNodes.push(...createInitialExpandedNodes(child, counter - 1));
        });
      }
      return expandedNodes;
    };
    const initialExpandedNodes = createInitialExpandedNodes(assetNodes[0]);
    setExpanded(curr => [...curr, ...initialExpandedNodes]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetNodes]);

  return (
    <DataSetsWizardContext.Provider
      value={{
        assetNodes,
        selectedTemplate,
        setSelectedTemplate,
        openDataSetWizard,
        setOpenDataSetWizard,
        handleOpenCloseWizard,
        templateAssets,
        selectedAssets,
        setSelectedAssets,
        expanded,
        setExpanded,
        allAssets,
        setAllAssets,
        topLevelAssetType,
      }}
    >
      {children}
    </DataSetsWizardContext.Provider>
  );
};

DataSetsWizardProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
