import React, { useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { auditApi, parametersApi, handleApiError } from 'api';

export const HistoryContext = React.createContext();

export const HistoryProvider = ({ children }) => {
  const [allHistories, setAllHistories] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
  const [allParameters, setAllParameters] = useState([]);
  const [areParametersLoaded, setAreParametersLoaded] = useState(false);
  const [areAssetsLoaded, setAreAssetsLoaded] = useState(false);
  const [allAssets, setAllAssets] = useState([]);

  const { projectId } = useParams();

  useEffect(() => {
    setIsHistoryLoaded(false);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getHistory = async after => {
      const query = {
        project_id: projectId,
      };

      if (after) query.after = after;
      const response = await auditApi('getHistory', query, source.token).catch(
        handleApiError
      );
      if (!didCancel && response) {
        const {
          snapshots,
          // paging
        } = response;

        setAllHistories(curr => [...curr, ...snapshots]);

        // if (paging?.cursors?.after) {
        //   await getHistory(paging?.cursors?.after);
        // }
        setIsHistoryLoaded(true);
      }
      return () => {
        didCancel = true;
        source.cancel();
      };
    };

    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setAreParametersLoaded(false);
    setAreAssetsLoaded(false);
    const source = axios.CancelToken.source();
    let didCancel = false;

    const getParameters = async (newParameterIds, after) => {
      const query = {
        project_id: projectId,
        parameter_id: newParameterIds,
        show_deleted: true,
      };
      if (after) query.after = after;
      const response = await parametersApi(
        'getAllParameters',
        query,
        source.token
      ).catch(handleApiError);

      if (!didCancel && response) {
        const { parameters: parametersList, paging } = response;

        setAllParameters(curr => [...curr, ...parametersList]);

        if (paging?.cursors?.after) {
          await getParameters(newParameterIds, paging?.cursors?.after);
        }
        setAreParametersLoaded(true);
      }
    };

    const getAssets = async (assetIds, after) => {
      const query = {
        project_id: projectId,
        asset_id: assetIds,
        show_deleted: true,
      };
      if (after) query.after = after;
      const response = await parametersApi(
        'getAssets',
        query,
        source.token
      ).catch(handleApiError);

      if (!didCancel && response) {
        const { assets: assetsList, paging } = response;

        setAllAssets(curr => [...curr, ...assetsList]);

        if (paging?.cursors?.after) {
          await getAssets(assetIds, paging?.cursors?.after);
        }
        setAreAssetsLoaded(true);
      }
    };

    if (isHistoryLoaded) {
      setAllParameters([]);
      setAllAssets([]);

      const newParameterIds = Array.from(
        new Set(
          allHistories
            .filter(historyItem => historyItem.parameterId !== undefined)
            .map(historyItem => historyItem.parameterId)
        )
      );

      const assetIds = Array.from(
        allHistories.reduce((set, historyItem) => {
          if (historyItem.assetId !== undefined) {
            set.add(historyItem.assetId);
            if (historyItem.parents?.[0]?.id !== null) {
              set.add(historyItem.parents[0].id);
            }
          }
          return set;
        }, new Set())
      );

      getParameters(newParameterIds);
      getAssets(assetIds);
    }

    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistoryLoaded]);

  useEffect(() => {
    setIsLoaded(false);

    if (areParametersLoaded && areAssetsLoaded) {
      const historiesWithDetails = allHistories.map(history => {
        if (history.parameterId !== undefined) {
          const parameter = allParameters.find(
            param => param.id === history.parameterId
          );
          return {
            ...history,
            parameterName: parameter?.parameterType?.name,
            parentName: parameter?.parents[0]?.name,
            parentId: parameter?.parents[0]?.id,
          };
        }
        if (history.assetId !== undefined) {
          const asset = allAssets.find(
            assetItem => assetItem.id === history.assetId
          );
          const parentAsset = allAssets.find(
            parentAssetItem => parentAssetItem.id === history.parents[0]?.id
          );
          return {
            ...history,
            currentAssetName: asset?.name,
            assetTypeName: asset?.assetType?.name,
            parentName: parentAsset?.name,
          };
        }
        return history;
      });

      setAllHistories(historiesWithDetails);
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areParametersLoaded, areAssetsLoaded]);

  const contextValue = useMemo(
    () => ({
      isLoaded,
      allHistories,
    }),
    [isLoaded, allHistories]
  );

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
};

HistoryProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
