/* eslint-disable no-nested-ternary */
import {
  Collapse,
  Box,
  Skeleton,
  Typography,
  Grid,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { TreeView } from '@mui/lab';
import Alert from 'components/alerts';
import TreeItem from 'components/tree/tree-item';
import { useTranslation } from 'react-i18next';
import { ChevronRight, ExpandMore, Circle } from '@mui/icons-material';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';
import { capitalize, isEmpty } from 'lodash';
import { StyledIconButton } from 'components/buttons';
import { styled } from '@mui/material/styles';
import { treeItemClasses } from '@mui/lab/TreeItem';
import useSummary from '../hooks/use-summary';

function AssetTypesSummary() {
  const { t } = useTranslation(['common', 'parameters', 'templates']);
  const {
    openSummary,
    handleOpen,
    loadingAssets,
    formattedAssets,
    loadingParameters,
    assetNodes,
    error,
  } = useSummary();

  const summaryText = [
    { key: t('templates:templateSummary.aNew'), fontWeight: 'regular' },
    {
      key: t('templates:templateSummary.dataSet'),
      fontWeight: 'bold',
    },
    {
      key: t('templates:templateSummary.willCreateParams'),
      fontWeight: 'regular',
    },
    {
      key: t('templates:templateSummary.assetTypeInstances'),
      fontWeight: 'bold',
    },
  ];

  const StyledTreeItem = styled(props => <TreeItem {...props} />)(
    ({ theme }) => ({
      [`& .${treeItemClasses.content}`]: {
        alignItems: 'flex-start',
      },
      [`& .${treeItemClasses.label}`]: {
        alignItems: 'center',
        paddingLeft: 0,
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
      },
    })
  );

  const renderTree = nodes => {
    return (
      <StyledTreeItem
        key={nodes.id}
        nodeId={nodes.id}
        isTemplateDetails
        labelText={
          <>
            <Grid container spacing={0.5}>
              <Grid item>
                <Typography variant="body2">
                  {nodes.assetType?.name
                    ? capitalize(nodes.assetType.name)
                    : capitalize(nodes.name)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={nodes.count ? 'body2' : 'body1'}
                  fontWeight="bold"
                  aria-label="asset-type-name-tree"
                >
                  {nodes.count
                    ? `(${nodes.count})`
                    : `(${t('templates:anyCount')})`}
                </Typography>
              </Grid>
            </Grid>
            <List
              aria-label="asset-level-info"
              key={nodes.id}
              role="list"
              sx={{ pt: 0 }}
            >
              <ListItem
                sx={{
                  pt: 0,
                  pb: 0,
                  pl: 1,
                  pr: 0,
                  textTransform: 'none',
                }}
                key={nodes.id}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                  primary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight="medium"
                        color="textPrimary"
                        aria-label="asset-placeholder-name"
                      >
                        {`${t('common:name')}: `}
                      </Typography>
                      {nodes.assetSubType?.name
                        ? nodes.assetSubType.name
                        : nodes.name}
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight="medium"
                        color="textPrimary"
                        aria-label="asset-placeholder-description"
                      >
                        {`${capitalize(t('common:description'))}: `}
                      </Typography>
                      {nodes.description}
                    </>
                  }
                />
              </ListItem>
            </List>
          </>
        }
      >
        {!isEmpty(nodes.children)
          ? nodes.children.map(node => renderTree(node))
          : null}
      </StyledTreeItem>
    );
  };

  const renderAssetsWithParameters = asset => {
    if (!isEmpty(asset.parameters)) {
      return (
        <div key={asset.id}>
          <Typography
            variant="body2"
            aria-label="asset-type-name-parameters-list"
            sx={{
              marginLeft: theme => theme.spacing(1.5),
              textTransform: 'capitalize',
            }}
          >{`${asset.assetType?.name ? asset.assetType.name : asset.name} (${
            asset.name
          }):`}</Typography>
          <List aria-label="parameters-list" key={asset.id} role="list">
            {asset.parameters.map(parameter => (
              <ListItem
                sx={{ pt: 0, pb: 0, pl: theme => theme.spacing(3) }}
                aria-label="parameter-name"
                key={parameter.id}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '16px',
                  }}
                >
                  <Circle
                    sx={{
                      fontSize: '6px',
                      // eslint-disable-next-line no-confusing-arrow
                      color: theme =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={parameter.parameterTypeName}
                />
              </ListItem>
            ))}
          </List>
          {!isEmpty(asset.children) &&
            asset.children.map(renderAssetsWithParameters)}
        </div>
      );
    }

    return (
      !isEmpty(asset.children) && asset.children.map(renderAssetsWithParameters)
    );
  };

  return (
    <Box aria-label="contentBox" width="auto">
      {!isEmpty(error) ? (
        <Box sx={{ padding: theme => theme.spacing(3) }}>
          <Alert
            ariaLabel="templateAssetAlert"
            title={error?.msg}
            text={error?.details}
          />
        </Box>
      ) : (
        <>
          <ExpandOrCollapseButton
            buttonSize="small"
            open={openSummary}
            onClick={handleOpen}
            sx={{ marginLeft: theme => theme.spacing(-1.5) }}
          />
          <Typography variant="caption" display="inline" color="textSecondary">
            {t('common:summary')}
          </Typography>
          <Collapse in={openSummary}>
            {summaryText.map(text => (
              <Typography
                key={text.key}
                variant="body1"
                display="inline"
                fontWeight={text.fontWeight}
              >
                {text.key}
              </Typography>
            ))}
            {loadingAssets || isEmpty(assetNodes) ? (
              <Skeleton
                height={30}
                width="100%"
                display="inline"
                aria-label="loading-assets"
              />
            ) : (
              <TreeView
                aria-label="asset-tree"
                sx={{
                  paddingTop: theme => theme.spacing(1.25),
                  paddingBottom: theme => theme.spacing(1.25),
                }}
                defaultCollapseIcon={
                  <StyledIconButton buttonSize="small" icon={<ExpandMore />} />
                }
                defaultExpandIcon={
                  <StyledIconButton
                    buttonSize="small"
                    icon={<ChevronRight />}
                  />
                }
                disableSelection
                defaultExpanded={[...assetNodes]}
              >
                {!isEmpty(formattedAssets) &&
                  renderTree(formattedAssets, 'assets')}
              </TreeView>
            )}
            <Typography
              variant="body1"
              display="inline-block"
              sx={{ marginBottom: theme => theme.spacing(1) }}
            >
              {t('templates:templateParametersInfo')}
            </Typography>
            {loadingParameters || isEmpty(assetNodes) ? (
              <Skeleton
                height={30}
                width="100%"
                display="inline"
                aria-label="loading-parameters"
              />
            ) : (
              renderAssetsWithParameters(formattedAssets)
            )}
          </Collapse>
        </>
      )}
    </Box>
  );
}

export default AssetTypesSummary;
