import { Routes, Route, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { MetaHeader } from 'components/meta';
import HeaderToolbar from 'components/header';
import ProjectsPage from 'routes/projects-page';
import { CookieConsent } from 'components/snackbars';
import AcceptableUseCookiesPopup from 'routes/home/cookies';
import config from 'config';
import UserSettings from 'routes/user-settings';
import ErrorPage from 'routes/error-page';
import ProjectPages from 'routes/project-pages';
import HomePage from 'routes/home';
import withRoot from './with-root';
import PrivateRoute from './private';

posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
  api_host: process.env.REACT_APP_POSTHOG_API_URL,
});

export const App = () => (
  <PostHogProvider client={posthog}>
    <MetaHeader />
    <Suspense fallback={null}>
      {config.featureFlags.arePoliciesEnabled ? (
        <AcceptableUseCookiesPopup />
      ) : (
        <CookieConsent />
      )}
    </Suspense>
    <HeaderToolbar />
    <Routes>
      <Route path="/" element={<HomePage />} index />

      <Route
        path="/projects"
        element={
          <PrivateRoute>
            <ProjectsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:projectId/*"
        element={
          <PrivateRoute>
            <ProjectPages />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-settings/*"
        element={
          <PrivateRoute>
            <UserSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/error"
        element={
          <Suspense fallback={null}>
            <ErrorPage />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/error" replace />} />
    </Routes>
  </PostHogProvider>
);

export default withRoot(App);
