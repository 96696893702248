import React, { useContext } from 'react';
import { HistoryContext } from 'context';
import { Box, Typography, Skeleton } from '@mui/material';
import { format, isToday, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import AuditItemAccordion from '../audit-item-accordion/audit-item-accordion';

const GroupedHistoriesByDate = () => {
  const { allHistories, isLoaded } = useContext(HistoryContext);
  const { t } = useTranslation(['histories']);

  const formatDate = dateString => {
    const date = parseISO(dateString);
    return isToday(date) ? t('histories:today') : format(date, 'MMMM d, yyyy');
  };

  const historiesByDate = allHistories.reduce((acc, history) => {
    const date = formatDate(history.updatedAt);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(history);
    return acc;
  }, {});

  return (
    <Box sx={{ p: theme => theme.spacing(3) }}>
      {Object.entries(historiesByDate).map(([date, histories]) => (
        <Box key={date} aria-label="grouped-history">
          <Box
            sx={{
              pb: theme => theme.spacing(3),
              pt: theme => theme.spacing(3),
            }}
          >
            {isLoaded ? (
              <Typography variant="button" aria-label="grouped-date">
                {date}
              </Typography>
            ) : (
              <Skeleton variant="rectangular" width={300} height={30} />
            )}
          </Box>
          {histories.map((history, index) => (
            <AuditItemAccordion
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              history={history}
              isLoaded={isLoaded}
              isLastAccordion={index === histories.length - 1}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(GroupedHistoriesByDate);
